import React from 'react'
import { rhythm, scale } from "../utils/typography"
import { Link } from "gatsby"

export const Articles = ({posts}) => {
  return posts.map(({ node }) => {
    if (node.frontmatter.draft) return null
    const title = node.frontmatter.title || node.fields.slug
    return (
      <article key={node.fields.slug}>
        <header>
          <h3
            style={{
              ...scale(1 / 4),
              lineHeight: '1.4',
              marginBottom: rhythm(1 / 4),
            }}
          >
            <Link style={{ boxShadow: `none`, color: '#1D4ED8' }} to={node.fields.slug}>
              {title}
            </Link>
          </h3>
        </header>
        <section>
          <p
            style={{color: 'hsla(0,0%,0%,0.6)'}}
            dangerouslySetInnerHTML={{
              __html: node.frontmatter.description || node.excerpt,
            }}
          />
        </section>
      </article>
    )
  })
}