import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Articles } from "../components/articles"
import { rhythm } from "../utils/typography"

const AllArticles = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <>
      <Layout location={location} title={siteTitle}>
        <SEO title="Home" />
        <h1
          style={{
            fontFamily: 'Montserrat, Arial Black, sans-serif',
            fontWeight: 900,
            marginTop: rhythm(1),
            marginBottom: 0,
          }}
        >
          All articles
        </h1>
        <Articles posts={posts} />
      </Layout>
    </>
  )
}

export default AllArticles

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            draft
          }
        }
      }
    }
  }
`
